.findCont {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: #f3f3f3; }
  .findCont .header .headerCenter {
    padding: 6px 0;
    background-color: #fff; }
    .findCont .header .headerCenter .content {
      width: 1190px !important;
      margin: 0 auto;
      height: 58px; }
      .findCont .header .headerCenter .content .left {
        float: left;
        display: flex;
        align-items: center; }
        .findCont .header .headerCenter .content .left .welcomeTitle {
          display: inline-block;
          font-size: 18px;
          color: #004D85;
          margin-left: 16px; }
        .findCont .header .headerCenter .content .left .welcomeCommon {
          color: #004d85;
          font-size: 17px;
          margin-left: 8px; }
      .findCont .header .headerCenter .content .right {
        float: right;
        width: auto;
        margin-top: 6px; }
  .findCont .title {
    margin: 30px auto;
    width: 1188px;
    font-size: 24px; }
  .findCont .container {
    width: 1188px;
    height: 400px;
    background-color: #fff;
    margin: 0 auto;
    margin-top: 11px;
    margin-bottom: 15px; }
    .findCont .container .formContainer {
      margin: 0 auto;
      width: 600px;
      padding-top: 50px; }
      .findCont .container .formContainer input {
        width: 307px;
        height: 36px;
        line-height: 30px;
        border: 1px solid #e1e1e0;
        border-radius: 2px;
        text-indent: 2px; }
      .findCont .container .formContainer .inputSms {
        position: relative; }
      .findCont .container .formContainer .smsInput {
        width: auto;
        background-color: initial;
        text-align: center;
        border: initial;
        display: inline-block;
        margin: 4px auto;
        height: 26px;
        line-height: 26px;
        position: absolute;
        top: 3px;
        right: 6px; }
      .findCont .container .formContainer .smsDevide {
        margin-right: 5px;
        color: #CDCDCD; }
      .findCont .container .formContainer .smsBomb {
        color: #1F7BF9;
        cursor: pointer; }
      .findCont .container .formContainer Button {
        width: 140px !important;
        border-radius: 5px !important;
        height: 38px;
        line-height: 34px;
        border: 1px solid #e1e1e0;
        border-radius: 2px;
        margin-left: 55px; }
