.headerbar {
  position: relative;
  width: 100%;
  height: 64px; }

.sidertrigger {
  display: inline-block;
  color: #5c6b77;
  height: 40px;
  margin-top: 15px;
  padding: 6px;
  text-align: center;
  width: 40px; }

.custombread {
  display: inline-block;
  vertical-align: top;
  margin-left: 20px; }

.usercon {
  float: right;
  height: auto;
  line-height: 64px;
  padding-right: 20px; }

.message {
  font-size: 18px;
  padding-right: 40px;
  color: #666; }
