@charset "UTF-8";
/* 公共样式 */
:global .navList {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2;
  height: 100%;
  overflow: auto;
  width: 200px; }
  :global .navList::-webkit-scroll {
    display: none; }

:global .searchLand {
  padding: 20px; }

:global .headerLogo, :global .headerLogo1 {
  display: block;
  padding: 15px 10px;
  height: 65px;
  background-color: #001529;
  overflow: hidden;
  display: flex;
  align-items: center; }
  :global .headerLogo img, :global .headerLogo1 img {
    display: inline-block;
    width: 35px;
    height: 35px; }
  :global .headerLogo span, :global .headerLogo1 span {
    color: #fff;
    padding-left: 10px;
    font-size: 16px; }

:global .headerLogo1 {
  padding: 15px 23px; }
  :global .headerLogo1 img {
    display: inline-block;
    width: 30px;
    height: 30px; }

:global #root .ant-spin {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  max-height: 100%; }

:global #root .ant-spin-nested-loading > div > .ant-spin {
  z-index: 99999;
  height: 100%;
  max-height: 100%; }

:global .mb25 {
  margin-bottom: 25px; }

:global .mb15 {
  margin-bottom: 15px; }

:global .mb20 {
  margin-bottom: 20px; }

:global .mb30 {
  margin-bottom: 30px; }
