.home {
  position: relative;
  height: 100vh; }
  .home .data {
    display: inline-block; }

.welcome {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  font-size: 24px;
  text-align: center;
  color: #999; }

.card {
  padding: 20px 0 0 40px;
  border-radius: 40px; }
  .card .button {
    margin: 0 20px;
    height: 40px;
    line-height: 40px;
    display: inline-block; }

.detail {
  display: inline-block;
  vertical-align: top;
  margin: 40px 0 0 0;
  border: 1px solid #e8e8e8;
  padding: 20px; }

.content {
  display: inline-block;
  vertical-align: top;
  min-height: 320px;
  margin-right: 40px; }

.statistics {
  display: inline-block;
  text-align: center;
  margin-right: 40px; }

.statisticsNum {
  display: block;
  text-align: center; }

.grey {
  color: #ccc; }

.totalNum {
  font-size: 40px;
  margin-bottom: 10px; }
