.top {
  margin-top: 20px;
  margin-left: 40px; }
  .top .searchArea .searchContent {
    width: 200px; }
  .top .searchArea .searchBtn {
    margin-left: 0; }

.statisticsNum {
  display: inline-block;
  margin-top: 20px; }

.chosen {
  display: block; }

.button {
  margin: 0 0 0 20px; }

.schoolModal.ant-calendar-picker {
  width: 472px !important; }
