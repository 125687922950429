.loginWrap {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background: url("../../assets/images/login.png") no-repeat top center; }
  .loginWrap .w1190 {
    width: 1120px;
    margin: 0 auto; }
  .loginWrap .flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between; }
  .loginWrap .logoCont {
    margin-top: 167px;
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .loginWrap .logoCont img {
      margin-top: 56px;
      width: 120px;
      height: 120px;
      display: inline-block; }
    .loginWrap .logoCont .title {
      height: 52px;
      font-size: 40px;
      font-family: MicrosoftYaHei;
      color: white;
      line-height: 52px;
      margin-top: 36px; }
  .loginWrap .loginCont {
    margin: 167px 0; }
    .loginWrap .loginCont .loginCon {
      width: 450px;
      height: 315px;
      background: white;
      box-shadow: 0px 4px 16px 0px rgba(153, 153, 153, 0.3);
      border-radius: 6px;
      font-size: 14px;
      transition: all 0.2s ease-in-out;
      padding: 0 62px; }
      .loginWrap .loginCont .loginCon .forget {
        font-size: 12px;
        display: flex;
        justify-content: flex-end; }
  .loginWrap .otherCont {
    margin-top: 33px;
    height: 180px;
    display: flex;
    flex-direction: row; }
    .loginWrap .otherCont .leftCont {
      width: 50%;
      box-sizing: border-box;
      border-right: 1px solid #ccc; }
      .loginWrap .otherCont .leftCont p {
        margin-bottom: 0;
        text-align: center; }
      .loginWrap .otherCont .leftCont .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 9px 0 16px; }
        .loginWrap .otherCont .leftCont .logo img {
          width: 60px;
          height: 60px; }
        .loginWrap .otherCont .leftCont .logo span {
          height: 44px;
          font-size: 33px;
          font-family: MicrosoftYaHei;
          color: #333333;
          line-height: 44px;
          margin-left: 24px; }
      .loginWrap .otherCont .leftCont .word {
        font-size: 20px;
        font-family: MicrosoftYaHei;
        color: #666666;
        line-height: 30px; }
      .loginWrap .otherCont .leftCont .time {
        margin-top: 6px;
        height: 24px;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: #666666;
        line-height: 24px; }
    .loginWrap .otherCont .rightCont {
      width: 50%;
      padding-left: 240px;
      padding-top: 9px; }
      .loginWrap .otherCont .rightCont .aboutUs {
        margin-bottom: 32px;
        height: 26px;
        font-size: 20px;
        font-family: MicrosoftYaHei;
        color: #666666;
        line-height: 26px; }
      .loginWrap .otherCont .rightCont .codes {
        display: flex;
        flex-direction: row; }
        .loginWrap .otherCont .rightCont .codes .codeLeft, .loginWrap .otherCont .rightCont .codes .codeRight {
          margin-right: 60px;
          display: flex;
          flex-direction: column;
          align-items: center; }
        .loginWrap .otherCont .rightCont .codes .codesImg {
          display: block;
          width: 80px;
          height: 80px;
          margin-bottom: 11px; }
        .loginWrap .otherCont .rightCont .codes .codesIcon {
          width: 16px;
          margin-right: 4px; }
        .loginWrap .otherCont .rightCont .codes span {
          height: 16px;
          font-size: 12px;
          font-family: MicrosoftYaHei;
          color: #999999;
          line-height: 16px; }
  .loginWrap .companyCont {
    margin-top: 24px; }
    .loginWrap .companyCont p {
      height: 16px;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      color: #999999;
      line-height: 16px;
      margin-bottom: 10px;
      text-align: center; }

.loginLogo {
  padding-top: 44px;
  text-align: center;
  height: 75px;
  font-size: 24px;
  font-family: MicrosoftYaHei;
  color: #666666;
  line-height: 31px;
  margin-bottom: 32px; }

.loginInput {
  display: block;
  margin: 0 auto;
  width: 326px;
  height: 40px;
  border-radius: 6px;
  box-sizing: border-box;
  border: 1px solid #cccccc; }

.loginbutton {
  display: block;
  margin: 0 auto;
  width: 326px;
  height: 40px;
  background: #409dff;
  border-radius: 6px; }
  .loginbutton span {
    font-size: 16px;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    color: white; }
