.top {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 40px; }

.statisticsNum {
  display: inline-block; }

.chosen {
  display: block; }

.btn {
  margin-left: 20px; }
