.totalGrade .title {
  display: inline-block; }

.totalGrade .content {
  display: inline-block; }
  .totalGrade .content .grade {
    width: 200px;
    height: 52px;
    padding: 5px;
    margin-right: 15px; }

.borderBottom {
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px; }

.label {
  display: inline-block; }

.addBtn {
  margin-top: 20px; }

.effectTime {
  margin-top: 20px; }

.btn {
  display: block;
  margin: 20px 45% 0; }
